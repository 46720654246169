import mainjson from '../config/main';
export default function GetData() {
        return {
        "apitype":"messages",
        "id":"message_id",
        "options":["edit","insert","delete"],
        "nameSingle":"message",
        "nameMultiple":"messages",
        "filters":
            [
               
            ]
        ,
        "fields":{
            "field1":{
                "name":"Subject",
                "field":"message_subject",
                "type":"TextInputMultiLanguage",
                "required":true,
                "list":true,
            },
            "field2":{
                "name":"Message",
                "field":"message_message",
                "type":"TextAreaMultiLanguage",
                "required":true,
            },
            "field3":{
                "name":"Specific user",
                "field":"message_user_id",
                "type":"TextInput",
                "subtype":"number",
                "required":true,
                "list":true,
                "default":"0"
            }, 
            "field4":{
                "name":"To users on event",
                "field":"message_event_id",
                "emptyfirstoption":"All events",
                "emptyfirstoptionvalue":"-1",
                "type":"DropDown",
                "options": "events",
                "list":true,
            },     
        }
    }
}